import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import jwtUtil from '@/utils/jwtUtil'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/routes/HomeRoute')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/routes/LoginRoute')
    },
    {
        path: '/members',
        name: 'Members',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/routes/MemberRoute')
    },
    {
        path: '/offers',
        name: 'Offers',
        component: () => import('@/routes/OfferRoute')
    },
    {
        path: '/partners',
        name: 'Partners',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/routes/PartnerRoute')
    },
    {
        path: '/dealers',
        name: 'Dealers',
        component: () => import('@/routes/DealerRoute')
    },
    {
        path: '/teams',
        name: 'Teams',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/routes/TeamRoute')
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('@/routes/UserRoute')
    },
    {
        path: '/transactions',
        name: 'Transactions',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/routes/TransactionRoute')
    },
    {
        path: '/image-gallery',
        name: 'Image Gallery',
        component: () => import('@/routes/ImageGalleryRoute')
    },
    {
        path: '/articles',
        name: 'Articles',
        component: () => import('@/routes/ArticleRoute')
    },
    {
        path: '/cards',
        name: 'Cards',
        component: () => import('@/routes/CardRoute')
    },
    {
        path: '/vouchers',
        name: 'Vouchers',
        component: () => import('@/routes/VoucherRoute')
    },
    {
        path: '/articles/:articleId/:mode',
        name: 'Article Form',
        component: () => import('@/forms/ArticleForm')
    },
    {
        path: '/members/:memberId/:mode',
        name: 'Member Form',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/forms/MemberForm')
    },
    {
        path: '/dealers/:dealerId/:mode',
        name: 'Dealer Form',
        component: () => import('@/forms/DealerForm')
    },
    {
        path: '/partners/:partnerId/:mode',
        name: 'Partner Form',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/forms/PartnerForm')
    },
    {
        path: '/teams/:teamId/:mode',
        name: 'Team Form',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/forms/TeamForm')
    },
    {
        path: '/users/:userId/:mode',
        name: 'User Form',
        component: () => import('@/forms/UserForm')
    },
    {
        path: '/transactions/:transactionId/:mode',
        name: 'Transaction Form',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/forms/TransactionForm')
    },
    {
        path: '/cards/:cardId/:mode',
        name: 'Card Form',
        component: () => import('@/forms/CardForm')
    },
    {
        path: '/vouchers/:voucherId/:mode',
        name: 'Voucher Form',
        component: () => import('@/forms/VoucherForm')
    },
    {
        path: '/offers/:offerId/:mode',
        name: 'Offer Form',
        component: () => import('@/forms/OfferForm')
    },
    {
        path: '/articles/:mode',
        name: 'Create Article Form',
        component: () => import('@/forms/ArticleForm')
    },
    {
        path: '/members/:mode',
        name: 'Create Member Form',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/forms/MemberForm')
    },
    {
        path: '/dealers/:mode',
        name: 'Create Dealer Form',
        component: () => import('@/forms/DealerForm')
    },
    {
        path: '/cards/:mode',
        name: 'Create Card Form',
        component: () => import('@/forms/CardForm')
    },
    {
        path: '/vouchers/:mode',
        name: 'Create Voucher Form',
        component: () => import('@/forms/VoucherForm')
    },
    {
        path: '/offers/:mode',
        name: 'Create Offer Form',
        component: () => import('@/forms/OfferForm')
    },
    {
        path: '/partners/:mode',
        name: 'Create Partner Form',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/forms/PartnerForm')
    },
    {
        path: '/teams/:mode',
        name: 'Create Team Form',
        meta: {
            roles: ['dealer']
        },
        component: () => import('@/forms/TeamForm')
    },
    {
        path: '/users/:mode',
        name: 'Create User Form',
        component: () => import('@/forms/UserForm')
    },
    {
        path: '/transactions/:mode',
        name: 'Transaction Form',
        component: () => import('@/forms/TransactionForm')
    },
    {
        path: '/about-us',
        name: 'About Us',
        component: () => import('@/routes/AboutUsRoute')
    },
    {
        path: '/about-us/:mode',
        name: 'About Us Form',
        component: () => import('@/routes/AboutUsRoute')
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('@/routes/StatsRoute')
    },
    {
        path: '/promos',
        name: 'Promos',
        component: () => import('@/routes/PromoRoute')
    },
    {
        path: '/promos/:promoId/:mode',
        name: 'Create Promo Form',
        component: () => import('@/forms/PromoForm')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const currentUser = store.getters.currentUser
    const token = store.getters.getToken
    if ((!token || (token && jwtUtil.isJwtExpired(token))) && to.name !== 'Login') {
        store.dispatch('logout').then(() => next({ path: '/login' }))
    } else if (!currentUser && to.name === 'Login') {
        next()
    } else if (currentUser.role === 'admin' || (to.meta.roles && to.meta.roles.includes(currentUser.role))) {
        next()
    }
})

export default router
